import { AfterViewInit, DestroyRef, Directive, inject, OnDestroy } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { PlatformService } from '@services/platform.service';
import { Regex } from '@configs/regex.config';
import { InputMask } from 'primeng/inputmask';
import { DateMask } from '@interfaces/date-mask.interface';

@Directive({
  standalone: true,
  selector: '[pDateMask]'
})
export class DateMaskDirective implements AfterViewInit {
  private readonly destroy = inject(DestroyRef);

  constructor(private primeCalendar: Calendar) {
    this.destroy.onDestroy(() => {
      this.getCalendarInputElement().removeEventListener('input', this.handleInputEvent);
    });
  }

  ngAfterViewInit(): void {
    this.getCalendarInputElement().addEventListener('input', this.handleInputEvent);
  }

  getCalendarInputElement(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input');
  }

  getDateMask(): DateMask[] {
    if(this.primeCalendar.timeOnly){
      return [
        {length: 2, regex: Regex.onlyHour, replace: `$1:`},
        {length: 5, regex: Regex.time, replace: `$1:$2`}
      ];
    } else if(this.primeCalendar.showTime){
      return [
        {length: 2, regex: Regex.dateDay, replace: `$1/`},
        {length: 5, regex: Regex.dateDayWithMonth, replace: `$1/$2/`},
        {length: 7, regex: Regex.fullDate, replace: `$1/$2/$3`},
        {length: 13, regex: Regex.fullDateWithHour, replace: `$1/$2/$3 $4:`},
        {length: 16, regex: Regex.fullDateWithTime, replace: `$1/$2/$3 $4:$5`}
      ];
    } else {
      return [
        {length: 2, regex: Regex.dateDay, replace: `$1/`},
        {length: 5, regex: Regex.dateDayWithMonth, replace: `$1/$2/`},
        {length: 7, regex: Regex.fullDate, replace: `$1/$2/$3`},
      ];
    }
  }

  handleInputEvent = (event: Event) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9:/\s]/g, '');
    const value = inputElement.value;

    const mask = this.getDateMask();

    for (const dateMask of mask) {
      if(value.length === dateMask.length) {
        inputElement.value = value.replace(dateMask.regex, dateMask.replace);
        break;
      }
    }
  }

}
