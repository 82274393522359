import { Promotion } from '@interfaces/promotion.interface';
import { Dish } from './dish.model';

/**
 * Represents a bundle of dishes.
 */
export class Bundle {
  /**
   * The creation date of the bundle.
   */
  created_at?: string;

  /**
   * Indicates if the bundle is available for delivery.
   */
  delivery?: boolean;

  /**
   * The description of the bundle.
   */
  description?: string;

  /**
   * The list of dishes included in the bundle.
   */
  dishes?: Dish[];

  /**
   * The unique identifier of the bundle.
   */
  id?: number;

  /**
   * The name of the bundle.
   */
  name?: string;

  /**
   * The URL of the bundle's photo.
   */
  photo_url?: string;

  /**
   * The price of the bundle.
   */
  price?: string;

  /**
   * The estimated waiting time for the bundle.
   */
  time_wait?: number;

  /**
   * The promotion associated with the bundle.
   */
  promotion?: Promotion;

  /**
   * Creates an instance of the Bundle class.
   * @param data - The data to initialize the bundle.
   */
  constructor(data: Bundle) {
    Object.assign(this, data);

    if (data.dishes) {
      this.dishes = data.dishes.map((dish: Dish) => new Dish(dish));
    }
  }
}
