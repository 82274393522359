@if (platform.isPlatformBrowser()) {
  <p-dialog
    [modal]="true"
    [(visible)]="ageRestrictedDialogService.ageRestrictedDialogVisible"
    [draggable]="false"
    [closable]="false"
    [focusOnShow]="false"
    position="center"
    styleClass="dialog-age-restriction">
    <ng-template pTemplate="headless">
      <h1 class="age-restriction__header mt-0">{{ 'RestaurantsPage.AgeRestrictionDialog.Zone' | translate }} 18+</h1>
      <div class="age-restriction__content">
        <p class="age-restriction__text">{{ 'RestaurantsPage.AgeRestrictionDialog.Zone description' | translate }}</p>

        <div class="age-restriction__input__container">
          <label for="age-restriction-input">{{
            'RestaurantsPage.AgeRestrictionDialog.Age restriction date of birth' | translate
          }}</label>
          <p-calendar
            type="date"
            [(ngModel)]="date"
            id="age-restriction-input"
            class="age-restriction__input__container__date"
            appendTo="body"
            placeholder="dd/mm/rrrr"
            styleClass="calendar-age-restriction"
            dateFormat="dd/mm/yy"
            pDateMask
            [touchUI]="true"
            (onFocus)="focusOnDateHandler()"
            (onBlur)="blurOnDateHandler()"></p-calendar>
          @if (hasError) {
            <p class="age-restriction__date-error">
              {{ errorMessage() | translate }}
            </p>
          }
        </div>

        <div class="age-restriction__actions">
          <p class="age-restriction__actions-warning">
            {{ 'RestaurantsPage.AgeRestrictionDialog.Age restriction warning' | translate }}
          </p>
          <button (click)="goBackToRestaurantsList()" pButton pRipple class="age-restriction__actions__button-back">
            {{ 'RestaurantsPage.AgeRestrictionDialog.Go Back' | translate }}
          </button>
          <button
            (click)="setUserDateOfBirth()"
            [disabled]="dateEmpty()"
            pButton
            pRipple
            class="age-restriction__actions__button-confirm">
            {{ 'RestaurantsPage.AgeRestrictionDialog.Confirm' | translate }}
          </button>
        </div>

        <p class="age-restriction__disclaimer">
          {{ 'RestaurantsPage.AgeRestrictionDialog.Age restriction disclaimer' | translate }}
        </p>
      </div>
    </ng-template>
  </p-dialog>
}
