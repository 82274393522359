import { Component, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DatesConfig } from '@configs/dates.config';
import { DateMaskDirective } from '@directives/date-mask.directive';
import { User } from '@models/user.model';
import { AgeRestrictedDialogService } from '@modules/age-restricted-dialog/age-restricted-dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { RestaurantDetailsService } from '@pages/restaurants/pages/restaurant-details/restaurant-details.service';
import { NotificationsService } from '@services/notifications.service';
import { PlatformService } from '@services/platform.service';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import { DateTime } from 'luxon';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-age-restricted-dialog',
  templateUrl: './age-restricted-dialog.component.html',
  styleUrl: './age-restricted-dialog.component.scss',
  imports: [
    DialogModule,
    TranslateModule,
    ButtonModule,
    CalendarModule,
    RippleModule,
    FormsModule,
    InputMaskModule,
    DateMaskDirective,
  ],
  providers: [AgeRestrictedDialogService],
  standalone: true,
})
export class AgeRestrictedDialogComponent {
  protected readonly ageRestrictedDialogService = inject(AgeRestrictedDialogService);
  private readonly restaurantsService = inject(RestaurantDetailsService);
  private readonly storageService = inject(StorageService);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly notificationsService = inject(NotificationsService);
  readonly platform = inject(PlatformService);

  date = signal<Date | null>(null);
  dateEmpty = computed<boolean>(() => this.date() === null);
  errorMessage = computed<string>(() =>
    this.date() !== null
      ? 'RestaurantsPage.AgeRestrictionDialog.Age restriction date of birth error'
      : 'Validators.Required'
  );

  public get hasError() {
    return this.ageRestrictedDialogService.ageRestrictionDateHasError();
  }

  public set hasError(value: boolean) {
    this.ageRestrictedDialogService.ageRestrictionDateHasError.set(value);
  }

  public get currentUser() {
    return this.ageRestrictedDialogService.currentUser();
  }

  public set currentUser(value: User | null) {
    this.ageRestrictedDialogService.currentUser.set(value);
  }

  private calculateAgeRestrictionDate(date: Date): boolean {
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();
    const dateDay = date.getDate();
    const dateMonth = date.getMonth();
    const dateYear = date.getFullYear();

    const ageRestrictedYear = todayYear - dateYear;
    const ageRestrictedMonth = todayMonth - dateMonth;
    const ageRestrictedDay = todayDay - dateDay;

    if (ageRestrictedYear > 18) {
      return true;
    }

    return ageRestrictedDay >= 0 && ageRestrictedMonth >= 0 && ageRestrictedYear >= 18;
  }

  constructor() {
    effect(
      () => {
        if (this.restaurantsService.restaurant()) {
          const restaurant = this.restaurantsService.restaurant();
          if (restaurant?.age_restricted && !this.checkIfUserIsEligible()) {
            setTimeout(() => {
              this.showDialog();
            }, 400);
          } else {
            this.hideDialog();
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  checkIfUserIsEligible(): boolean {
    this.currentUser = this.storageService.isLoggedIn()
      ? this.storageService.getUser()
      : this.storageService.getGuestUser();

    if (this.currentUser?.birth_date) {
      return this.calculateAgeRestrictionDate(new Date(this.currentUser.birth_date));
    }
    return false;
  }

  showDialog(): void {
    this.ageRestrictedDialogService.ageRestrictedDialogVisible.set(true);
  }

  hideDialog(): void {
    this.ageRestrictedDialogService.ageRestrictedDialogVisible.set(false);
  }

  focusOnDateHandler(): void {
    this.hasError = false;
  }

  blurOnDateHandler(): void {
    if (!this.date()) {
      this.hasError = true;
    }
  }

  isAdult(): boolean {
    if (this.date()) {
      return this.calculateAgeRestrictionDate(new Date(this.date()!));
    }
    return false;
  }

  setUserDateOfBirth(): void {
    if (this.date() && this.isAdult()) {
      const birth_date = DateTime.fromJSDate(this.date()!).toFormat(DatesConfig.DATE_BIRTH_DATE_FORMAT);
      this.currentUser = {
        ...this.currentUser,
        birth_date,
      };
      if (this.storageService.isLoggedIn()) {
        const userId = this.currentUser.id;
        this.storageService.saveUser(this.currentUser);
        this.userService
          .updateUser(String(userId), {
            birth_date: this.currentUser.birth_date,
          })
          .pipe(takeUntilDestroyed())
          .subscribe({
            next: () => this.ageRestrictedDialogService.ageRestrictedDialogVisible.set(false),
            error: () => this.notificationsService.error('Errors.SOMETHING_WENT_WRONG'),
          });
      } else {
        this.storageService.saveGuestUser(this.currentUser);
      }
    } else {
      this.hasError = true;
    }
  }

  goBackToRestaurantsList(): void {
    this.router.navigate(['restaurants']);
  }
}
