import { Injectable, signal } from '@angular/core';
import { User } from '@models/user.model';


@Injectable()
export class AgeRestrictedDialogService {
  readonly ageRestrictedDialogVisible = signal<boolean>(false);
  readonly ageRestrictionDateHasError = signal<boolean>(false);
  currentUser = signal<User | null>(null);
}
