import { HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { DestroyRef, Injectable, computed, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoutes } from '@configs/app-routes.config';
import { FoodCategoryVisibility } from '@enums/food-category-visibility.enum';
import { BookTableFormControls } from '@enums/form-controls.enum';
import { Langauge } from '@enums/language.enum';
import { CreateHttpParams } from '@functions/build-http-params.function';
import { AdditionsGroups } from '@interfaces/additions-groups.interface';
import { AlergenTag } from '@interfaces/alergen-tag.interface';
import { BundleAdditionsGroups } from '@interfaces/bundle-additions-groups.interface';
import { DishList } from '@interfaces/dish-list.interface';
import { FoodCategorySliderItem } from '@interfaces/food-category-slider-item.interface';
import { BookTableForm } from '@interfaces/forms/book-table-form.interface';
import { BookTableInput } from '@interfaces/inputs/book-table-input.interface';
import { DataResponse } from '@interfaces/responses/data-response.interface';
import { Bundle } from '@models/bundle.model';
import { Dish } from '@models/dish.model';
import { FoodCategory } from '@models/food-category.model';
import { Reservation } from '@models/reservation.model';
import { Restaurant } from '@models/restaurant.model';
import { TranslateService } from '@ngx-translate/core';
import { DiacreticPipe } from '@pipes/diacretic.pipe';
import { CartService } from '@services/cart.service';
import { ErrorService } from '@services/error.service';
import { NotificationsService } from '@services/notifications.service';
import { RestaurantsService } from '@services/restaurants.service';
import { StorageService } from '@services/storage.service';
import { Subject, forkJoin, takeUntil } from 'rxjs';

/**
 * The ID of the promotion category.
 */
const PROMOTION_CATEGORY_ID = 10000;

/**
 * The category ID for bundles.
 */
const BUNDLES_CATEGORY_ID = 10001;

/**
 * The position of the promotion category in the restaurant details.
 * This constant represents the index of the promotion category in the list of categories.
 */
const PROMOTION_CATEGORY_POSITION = -2;

/**
 * The position of the bundles category in the restaurant details.
 * This constant represents the index position of the bundles category in the restaurant details array.
 */
const BUNDLES_CATEGORY_POSITION = -1;

/**
 * The name of the promotion category.
 */
const PROMOTION_CATEGORY_NAME = 'promotions';

/**
 * The name of the category for bundles.
 */
const BUNDLES_CATEGORY_NAME = 'bundles';

/**
 * Default parameters for the restaurant details service.
 */
const DEFAULT_PARAMS = {
  withAddress: 1,
  withTags: 1,
  withPromotions: 1,
  withBundles: 1,
  with_attribute: 1,
  locale: Langauge.PL,
};

/**
 * Service responsible for managing restaurant details.
 */
@Injectable()
export class RestaurantDetailsService {
  /**
   * Service for managing restaurant details.
   */
  private readonly restaurantsService = inject(RestaurantsService);

  /**
   * Service for managing storage operations.
   */
  private readonly storageService = inject(StorageService);

  /**
   * Service for handling errors in the restaurant details module.
   */
  private readonly errorService = inject(ErrorService);

  /**
   * Service for handling notifications.
   */
  private readonly notificationsService = inject(NotificationsService);

  /**
   * Service for translating text.
   */
  private readonly translateService = inject(TranslateService);

  /**
   * A reference to the DiacreticPipe instance.
   */
  private readonly diacreticPipe = inject(DiacreticPipe);
  private readonly router = inject(Router);

  /**
   * Service responsible for managing the cart functionality.
   */
  readonly cartService = inject(CartService);

  /**
   * Signal indicating whether the service is currently processing.
   */
  readonly processing = signal<boolean>(false);

  /**
   * A signal indicating whether data is currently being fetched.
   */
  readonly fetching = signal<boolean>(true);

  /**
   * Represents the booking status.
   */
  readonly booking = signal<boolean>(false);

  /**
   * Signal containing restaurant details.
   */
  readonly restaurant = signal<Restaurant | null>(null);

  /**
   * Represents the bundles property of the RestaurantDetailsService class.
   * It holds the signal for bundles data.
   */
  readonly bundles = signal<Bundle[] | undefined>(undefined);

  /**
   * Controls the visibility of the book table dialog.
   */
  readonly bookTableDialogVisibility = signal<boolean>(false);

  /**
   * Controls the visibility of the restaurant info dialog.
   */
  readonly restaurantInfoDialogVisibility = signal<boolean>(false);
  /**
   * Indicates the visibility of the restaurant terms dialog.
   *
   * @remarks
   * This property is used to determine whether the restaurant terms dialog is visible or not.
   *
   * @typeParam boolean - The type of the visibility flag.
   */
  readonly restaurantTermsDialogVisibility = signal<boolean>(false);

  /**
   * Controls the visibility of the alergens dialog.
   */
  readonly alergensDialogVisibility = signal<{ visible: boolean; alergens: AlergenTag[] } | null>(null);

  /**
   * Controls the visibility of the additions dialog and stores the selected dish.
   */
  readonly selectAdditionsDialogVisibility = signal<{ visible: boolean; dish: Dish } | null>(null);

  /**
   * Represents the form group for booking a table.
   */
  readonly bookTableForm: FormGroup<BookTableForm> = this.initializeBookTableForm();

  /**
   * Represents the main food categories for a restaurant.
   */
  readonly mainFoodCategories = signal<FoodCategorySliderItem[]>([]);

  readonly notEmptyMainFoodCategories = computed(() => {
    return this.mainFoodCategories().filter((category: FoodCategorySliderItem) => !category.empty);
  });

  /**
   * Represents the list of dishes for a restaurant.
   */
  readonly dishList = signal<DishList[]>([]);

  /**
   * An array of `AdditionsGroups` representing the dish additions groups.
   */
  readonly dishAdditionsGroups = signal<AdditionsGroups[]>([]);

  /**
   * An array of `BundleAdditionsGroups` representing the bundle additions groups.
   */
  readonly bundleAdditionsGroups = signal<BundleAdditionsGroups[]>([]);

  /**
   * Represents the alergen tags for a restaurant.
   */
  readonly alergenTags = signal<AlergenTag[]>([]);

  /**
   * Indicates whether there are no search results.
   */
  readonly noSearchResults = signal<boolean>(false);

  /**
   * Represents the current category key.
   */
  readonly currentCategoryKey = signal<string>('0');

  intersectionObserverAdded = signal<boolean>(false);

  readonly tableReservationActive = signal<boolean>(false);

  /**
   * Validators for the phone field.
   * @returns An array of ValidatorFn functions.
   */
  get phoneValidators(): ValidatorFn[] {
    return [Validators.required, Validators.minLength(9)];
  }

  /**
   * Validators for the name field.
   * @returns An array of ValidatorFn functions.
   */
  get nameValidators(): ValidatorFn[] {
    return [Validators.required, Validators.minLength(3), Validators.maxLength(100)];
  }

  /**
   * Returns an array of validators for the description field.
   * The validators ensure that the description does not exceed 255 characters.
   *
   * @returns An array of ValidatorFn objects.
   */
  get descriptionValidators(): ValidatorFn[] {
    return [Validators.maxLength(255)];
  }

  /**
   * Returns an array of validator functions for the number of people input.
   * The validators include the required validator and a maximum value validator of 50.
   *
   * @returns An array of validator functions.
   */
  get peopleNumberValidators(): ValidatorFn[] {
    return [Validators.required, Validators.min(1), Validators.max(50)];
  }

  /**
   * Returns an array of date validators.
   * @returns An array of ValidatorFn objects.
   */
  get dateValidators(): ValidatorFn[] {
    return [Validators.required];
  }

  /**
   * Returns an array of hour validators.
   * @returns An array of ValidatorFn objects.
   */
  get hourValidators(): ValidatorFn[] {
    return [Validators.required];
  }

  destroyed$ = new Subject<void>();

  constructor() {
    inject(DestroyRef).onDestroy(() => {
      this.destroyed$.next();
      this.destroyed$.complete();
    });
  }

  /**
   * Returns the default HTTP parameters for the restaurant details service.
   * The locale parameter is set based on the language retrieved from the storage service.
   * @returns The default HTTP parameters.
   */
  private getDefaultParams(): HttpParams {
    DEFAULT_PARAMS.locale = this.storageService.getLanguage() as Langauge;
    return CreateHttpParams(DEFAULT_PARAMS);
  }

  /**
   * Retrieves the details of a restaurant.
   * @param hostname - The hostname of the restaurant.
   */
  public getRestaurantDetails(hostname: string): void {
    this.restaurantsService
      .getRestaurantDetails(hostname, this.getDefaultParams())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: DataResponse<Restaurant>) => this.handleGetRestaurantDetailsSuccess(response),
        error: (response: HttpErrorResponse) => this.handleGetRestaurantDetailsError(response),
      });
  }

  /**
   * Handles the success response for getting restaurant details.
   * @param response - The response containing the restaurant details.
   */
  private handleGetRestaurantDetailsSuccess(response: DataResponse<Restaurant>): void {
    if (response.bundles) {
      this.bundles.set(response.bundles.bundles.map((bundle: Bundle) => new Bundle(bundle)));
    }

    this.restaurant.set(new Restaurant(response.data));
    this.tableReservationActive.set(this.restaurant()!.table_reservation_active!);
    this.cartService.hostname.set(this.restaurant()!.hostname!);

    this.cartService.minimalPrice.set(parseFloat(this.restaurant()!.orderMinimalPrice!));

    const user = this.storageService.getUser() || this.storageService.getGuestUser();
    this.cartService.dbSync(this.restaurant()!.hostname!, user?.id!);
    this.setupDishList(this.restaurant()?.dishes!, this.restaurant()?.food_category!, this.bundles()!);
  }

  /**
   * Handles the error response for getting restaurant details.
   * @param response - The HTTP error response.
   */
  private handleGetRestaurantDetailsError(response: HttpErrorResponse): void {
    if (response.status === HttpStatusCode.NotFound) {
      this.router.navigate(['/' + AppRoutes.NotFound]);
      return;
    }

    this.fetching.set(false);
    this.errorService.throwError(response);
  }

  /**
   * Sets up the dish list by organizing the dishes into categories.
   *
   * @param dishes - The array of dishes to be organized.
   * @param categories - The array of food categories.
   */
  private setupDishList(dishes: Dish[], categories: FoodCategory[], bundles?: Bundle[]): void {
    const finalDishList: Record<string, Dish[] | undefined> = {};
    const categoriesDeepCopy: FoodCategory[] = JSON.parse(JSON.stringify(categories));
    const dishesWithPromotion = dishes.filter((dish: Dish) => dish.promotion);

    const promotionCategory: FoodCategory = {
      name: this.translateService.instant(`DishCategory.${PROMOTION_CATEGORY_NAME}`),
      id: PROMOTION_CATEGORY_ID,
      parent_id: null,
      position: PROMOTION_CATEGORY_POSITION,
      children: dishesWithPromotion,
      description: '',
      visibility: dishesWithPromotion.length > 0 ? FoodCategoryVisibility.VISIBLE : FoodCategoryVisibility.HIDDEN,
    };

    categoriesDeepCopy.unshift(promotionCategory);

    if (bundles) {
      const bundlesCategory: FoodCategory = {
        name: this.translateService.instant(`DishCategory.${BUNDLES_CATEGORY_NAME}`),
        id: BUNDLES_CATEGORY_ID,
        parent_id: null,
        position: BUNDLES_CATEGORY_POSITION,
        children: bundles.map(
          (bundle: Bundle) => new FoodCategory({ ...bundle, children: bundle.dishes, isBundle: true })
        ) as FoodCategory[],
        description: '',
        visibility: bundles.length > 0 ? FoodCategoryVisibility.VISIBLE : FoodCategoryVisibility.HIDDEN,
      };

      categoriesDeepCopy.unshift(bundlesCategory);
    }

    const visibleAndSortedByPositionCategories = categoriesDeepCopy
      .filter((category: FoodCategory) => category.visibility === FoodCategoryVisibility.VISIBLE)
      .sort((a: FoodCategory, b: FoodCategory) => a.position! - b.position!);

    const addChildrenToParent = (category: FoodCategory) => {
      if (!category.children) {
        category.children = [];
      }

      const children = visibleAndSortedByPositionCategories.filter(
        (child: FoodCategory) => child.parent_id === category.id
      );

      children.forEach((child: FoodCategory) => {
        addChildrenToParent(child);
        category.children?.push(new FoodCategory(child));
      });

      const dishesToAdd = dishes.filter((dish: Dish) => dish.food_category_id === category.id);
      category.children.push(...dishesToAdd);
    };

    visibleAndSortedByPositionCategories.forEach((category: FoodCategory) => {
      if (!category.parent_id) {
        addChildrenToParent(category);
        finalDishList[category.name!.toLowerCase()] = category.children;
      }
    });

    const dishMainCategories = Object.keys(finalDishList).map((name: string, index: number) => ({
      name,
      key: index.toString(),
      selected: false,
      empty: this.isEmptyCategory(Object.values(finalDishList)[index]),
    }));

    this.mainFoodCategories.set(dishMainCategories);

    const dishList = Object.keys(finalDishList).map((name: string) => ({
      name,
      items: finalDishList[name] ?? [],
    }));

    this.dishList.set(dishList);

    const firstNotEmptyCategoryIndex = Object.values(finalDishList).findIndex(
      categoryDishList => !this.isEmptyCategory(categoryDishList)
    );

    this.currentCategoryKey.set(firstNotEmptyCategoryIndex.toString());

    this.fetching.set(false);
  }

  /**
   * Checks if a category dish list is empty.
   * @param categoryDishList - The category dish list to check.
   * @returns `true` if the category dish list is empty, `false` otherwise.
   */
  private isEmptyCategory(categoryDishList: (Dish[] | FoodCategory[]) | undefined): boolean {
    if (!categoryDishList || categoryDishList.length === 0) {
      return true;
    }

    if (categoryDishList.every((item: Dish | FoodCategory) => item instanceof Dish)) {
      return false;
    }

    let count = 0;
    categoryDishList.forEach((category: Dish | FoodCategory) => {
      if (category instanceof FoodCategory && category.children) {
        count += category.children.length;
      }
    });

    return count === 0;
  }

  /**
   * Initializes the book table form.
   *
   * @returns A FormGroup instance representing the book table form.
   */
  private initializeBookTableForm(): FormGroup<BookTableForm> {
    return new FormGroup<BookTableForm>({
      [BookTableFormControls.NAME]: new FormControl(null, this.nameValidators),
      [BookTableFormControls.DESCRIPTION]: new FormControl(null, this.descriptionValidators),
      [BookTableFormControls.PHONE]: new FormControl(null, this.phoneValidators),
      [BookTableFormControls.PEOPLE_NUMBER]: new FormControl(1, this.peopleNumberValidators),
      [BookTableFormControls.DATE]: new FormControl(null, this.dateValidators),
      [BookTableFormControls.HOUR]: new FormControl(null, this.hourValidators),
    });
  }

  /**
   * Books a table for a restaurant.
   * @param input - The input data for booking the table.
   * @param hostname - The hostname of the server.
   */
  public bookTable(input: BookTableInput, hostname: string): void {
    this.booking.set(true);

    this.restaurantsService
      .bookTable(input, hostname)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: DataResponse<Reservation>) => this.handleBookTableSuccess(response),
        error: (response: HttpErrorResponse) => this.handleBookTableError(response),
      });
  }

  /**
   * Handles the success response after booking a table.
   * @param response - The data response containing the reservation details.
   */
  private handleBookTableSuccess(response: DataResponse<Reservation>): void {
    this.booking.set(false);
    this.bookTableDialogVisibility.set(false);
    this.notificationsService.success('RestaurantsPage.Table has been booked');
  }

  /**
   * Handles the error that occurs while booking a table.
   * @param response - The HTTP error response.
   */
  private handleBookTableError(response: HttpErrorResponse): void {
    this.booking.set(false);
    this.errorService.throwError(response);
  }

  /**
   * Searches for dishes in the restaurant based on the provided value.
   * @param value - The value to search for. Can be null.
   */
  public search(value: string | null): void {
    const normalizedValue = this.diacreticPipe.transform(value ?? '');
    const dishes = this.restaurant()?.dishes?.filter((dish: Dish) => {
      const name = this.diacreticPipe.transform(dish.name!)?.toLowerCase();
      const description = this.diacreticPipe.transform(dish.description!)?.toLowerCase();
      const value = normalizedValue?.toLowerCase() ?? '';

      return name?.includes(value) || description?.includes(value);
    });

    this.setupDishList(dishes!, this.restaurant()?.food_category!, this.bundles());
    this.noSearchResults.set(dishes?.length === 0);
  }

  /**
   * Retrieves the details of a dish by its ID.
   * @param id - The ID of the dish.
   */
  public getDishDetails(id: string): void {
    this.processing.set(true);
    this.restaurantsService
      .getDishDetails(this.restaurant()?.hostname!, id, this.getDefaultParams())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: DataResponse<Dish>) => this.handleGetDishDetailsResponse(response),
        error: (response: HttpErrorResponse) => this.handleGetDishDetailsError(response),
      });
  }

  /**
   * Handles the response for getting dish details.
   * @param response - The response containing the dish details.
   */
  private handleGetDishDetailsResponse(response: DataResponse<Dish>): void {
    this.dishAdditionsGroups.set(response.data.additions_groups ?? []);
    this.alergenTags.set(response.data.tags ?? []);
    this.processing.set(false);
  }

  /**
   * Handles the error response for getting dish details.
   * @param response - The HTTP error response.
   */
  private handleGetDishDetailsError(response: HttpErrorResponse): void {
    this.processing.set(false);
    this.errorService.throwError(response);
  }

  public getMultipleDishesDetails(ids: string[]): void {
    this.processing.set(true);

    const requests = ids.map((id: string) =>
      this.restaurantsService.getDishDetails(this.restaurant()?.hostname!, id, this.getDefaultParams())
    );

    forkJoin(requests)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (responses: DataResponse<Dish>[]) => {
          const bundleAdditionsGroups = responses.map(
            (response: DataResponse<Dish>): BundleAdditionsGroups => ({
              dish_id: response.data.id!,
              dish_name: response.data.name!,
              additions_groups: response.data.additions_groups!,
            })
          );
          this.bundleAdditionsGroups.set(bundleAdditionsGroups);
          this.processing.set(false);
        },
        error: (response: HttpErrorResponse) => {
          this.processing.set(false);
          this.errorService.throwError(response);
        },
      });
  }
}
